<template>
  <van-popup v-model="showPopup" round>
    <div class="modal">
      <div class="title">
        <span>Maximum Menu Item</span>
      </div>
      <div class="info" style="max-height: 220pt; overflow-y: scroll">
        Maximum {{ maxMenuItem }} menu items are allowed per order. 
        Please review your order, and remove one item before adding 
        a new one.
      </div>
      <div class="btn-box">
        <van-button class="decline-btn" size="large" @click="confirm"
          >OK
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      maxMenuItem: 0,
      showPopup: false,
    };
  },
  methods: {
    init(maxMenuItem) {
      this.maxMenuItem = maxMenuItem;
      this.showPopup = true;
    },
    confirm() {
      this.showPopup = false;
      this.$emit("MaximumCallback");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  width: 250pt;
  // height: 150pt;
  text-align: left;
  padding: 10px;

  .title {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14pt;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2b2e36;
    }
  }

  .info {
    margin-top: 10pt;
    font-size: 10pt;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: normal;
    color: #535353;
    padding: 0 10pt;
    // color: #979797;
    min-height: 50pt;
  }

  .order {
    margin: 10pt 0 5pt 0;
    padding: 10pt;
    border: 1px #535353 dashed;

    .text {
      font-size: 11pt;
      line-height: 15pt;
    }
  }

  .btn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8pt;
    margin-top: 1em;
    margin-bottom: 1em;

    .decline-btn {
      border-color: $primary-color;
      color: #fff;
      background-color: $primary-color;
      width: 35%;
      height: 30px;
    }
  }
}
</style>
