var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",class:[
    _vm.index == _vm.item.mainDishList.length - 1 && 'no-border',
    _vm.food.oosStatus && 'oos',
  ],on:{"click":function($event){return _vm.$emit('showFoodInfo', _vm.food)}}},[(_vm.food.image)?_c('div',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.preview(_vm.food.image)}}},[_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.food.image}})]):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"name"},[_c('span',{staticClass:"word-break"},[_vm._v(_vm._s(_vm.food.name))])]),_c('div',{staticClass:"price"},[_c('span',{staticClass:"word-break"},[_vm._v("  $"+_vm._s(_vm.food.fullPrice)+" ")])]),_c('div',{staticClass:"name",staticStyle:{"margin":"5px 0"}},[(
          _vm.food.type && _vm.food.type.split(',').indexOf('GF (Gluten Free)') != -1
        )?_c('div',{staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showIconInfo')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/1.png")}}),_c('img',{staticClass:"tip",attrs:{"src":require("@/assets/img/提示.png")}})]):_vm._e(),(
          _vm.food.type && _vm.food.type.split(',').indexOf('DF (Dairy Free)') != -1
        )?_c('div',{staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showIconInfo')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/2.png")}}),_c('img',{staticClass:"tip",attrs:{"src":require("@/assets/img/提示.png")}})]):_vm._e(),(_vm.food.type && _vm.food.type.split(',').indexOf('VG (Vegan)') != -1)?_c('div',{staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showIconInfo')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/3.png")}}),_c('img',{staticClass:"tip",attrs:{"src":require("@/assets/img/提示.png")}})]):_vm._e(),(
          _vm.food.type && _vm.food.type.split(',').indexOf('V (Vegetarian)') != -1
        )?_c('div',{staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showIconInfo')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/4.png")}}),_c('img',{staticClass:"tip",attrs:{"src":require("@/assets/img/提示.png")}})]):_vm._e(),(
          _vm.food.type && _vm.food.type.split(',').indexOf('NF (Nuts Free)') != -1
        )?_c('div',{staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('showIconInfo')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/5.png")}}),_c('img',{staticClass:"tip",attrs:{"src":require("@/assets/img/提示.png")}})]):_vm._e()]),_c('div',{staticClass:"info"},[(_vm.hotelInno === 'houit' || _vm.hotelInno === 'HOUIT')?_c('span',{staticClass:"word-break"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.food.description)}})]):_c('span',{staticClass:"word-break"},[_vm._v(" "+_vm._s(_vm.food.description)+" ")])]),(_vm.addFoodBtn)?_c('div',{staticClass:"add"},[(!_vm.food.oosStatus)?[(_vm.food.num > 0)?_c('van-badge',{attrs:{"content":_vm.food.num}},[_c('van-icon',{attrs:{"name":"add","size":"15pt"}})],1):_c('van-icon',{attrs:{"name":"add","size":"15pt"}})]:_c('div',[_c('span',{staticStyle:{"font-weight":"550"}},[_vm._v("Sold Out   ")])])],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }